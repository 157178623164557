<template>
    <div ref="modal_order">
        <b-modal id="bv-modal-show-order" hide-header hide-footer size="xl"  no-close-on-esc>
            <template #modal-footer>

            </template>

        <b-overlay :show="loading" rounded="sm">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-sm-5">
                                    <template v-if="order_id == 0">
                                        <h3>{{$t('main.creating_new_order')}}</h3>
                                    </template>
                                    <template v-else>
                                        <h3>{{$t('main.order', {number: order.number, date: order.ctime})}}
                                            <template v-if="typeof order.delivery_url !== 'undefined'">
                                              <a target="_blank" style="font-size: 60%" :href="order.delivery_url">{{order.number}}</a>
                                            </template>
                                        </h3>
                                    </template>

                                </div>
                                <div class="col-sm-7 text-right">
                                    <div class="input-group">
                                        <b-form-checkbox switch style="margin-top: 5px;margin-left: 20px" v-model="order.revision" :disabled="readonly">
                                            Требуется доработка
                                        </b-form-checkbox>&nbsp;
                                        <b-form-checkbox switch style="margin-left: 20px" v-model="order.express_delivery" :disabled="!(order.city_obj !== null && typeof order.city_obj.city_id !== 'undefined' && order.city_obj.city_id == 507730)" @change="changeExpress($event)">
                                        Express Delivery
                                        </b-form-checkbox>
                                        <b-form-select v-model="order.status" :options="so.options_status" style="margin-left: 20px; margin-right: 10px" :disabled="readonly"></b-form-select>
                                        <button class="btn btn-sm btn-info ml-1"  @click="showModalHistory(order.ether_id+'-'+order.order_id)">
                                            <b-icon-clock-history></b-icon-clock-history>
                                        </button>
                                        <b-button variant="success" @click="submitForm(false)" style="margin-left: 10px;" :disabled="disabled_send_form || readonly" >{{$t('main.save')}}</b-button>
                                        <b-button variant="danger" style="margin-left: 10px"  @click="cancelForm">{{$t('main.close')}}</b-button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <br/>
                            <div class="row">
                                <div class="col-sm-1">
                                    <nobr>{{$t('order.form.data.phone')}}: *</nobr>
                                </div>
                                <div class="col-sm-3">
                                    <phoneForm
                                            id="phone"
                                            ref="phone"
                                            :tabindex="1"
                                            :bus="localbus_p"
                                            @result="setPhoneObj"
                                            @setPhone="setPhone"
                                            :disabled="readonly"
                                            >
                                    </phoneForm>
                                </div>
                                <div class="col-sm-1">
                                    <nobr>{{$t('order.form.data.name')}}: *</nobr>
                                </div>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="order.firstName" id="firstName" ref="firstName"
                                               autocomplete="off" required tabindex="2" :disabled="readonly"
                                               @change="resetError($event)">
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <nobr>{{$t('order.form.data.surname')}}:</nobr>
                                </div>
                                <div class="col-sm-3">
                                    <input type="text" class="form-control" v-model="order.lastName" autocomplete="off" tabindex="3" :disabled="readonly">
                                </div>
                            </div>

                            <br/>
                            <div class="row">
                                <div class="col-sm-1">
                                    <nobr>{{$t('order.form.data.additional_phone')}}:</nobr>
                                </div>
                                <div class="col-sm-3">
                                    <input type="text" class="form-control" v-model="order.additionalPhone" id="additionalPhone"
                                           ref="additionalPhone" autocomplete="off" tabindex="4" :disabled="readonly">
                                </div>
                                <div class="col-sm-1">
                                    <nobr>{{$t('order.form.data.client_gender')}}: *</nobr>
                                </div>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <select class="form-control" v-model="order.sex" id="sex" ref="sex" @change="resetError($event)"
                                                tabindex="5" :disabled="readonly">
                                            <option value="male">{{$t('order.form.data.male')}}</option>
                                            <option value="female">{{$t('order.form.data.female')}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <nobr>{{$t('order.form.data.date_of_birth')}}:</nobr>
                                </div>
                                <div class="col-sm-3">
                                    <div class="input-group">
                                        <input type="date" class="form-control" v-model="order.birthdate" id="birthdate"
                                               ref="birthdate" autocomplete="off" tabindex="6" :disabled="readonly">
                                        <div class="invalid-feedback">
                                            {{$t('main.invalid_date_of_birth_format')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <template v-if="rule_order_source">
                                <div class="row" >
                                    <div class="col-sm-1">
                                        <nobr>{{$t('order.form.data.digital_source')}}:</nobr>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="input-group">
                                            <select class="form-control" v-model="order.source" id="source" ref="source" tabindex="7" :disabled="readonly">
                                                <template v-if="ether_id == 0">
                                                    <option v-for="(obj, index) in reference.digital_source.free" :value="obj.value">{{obj.text}}</option>
                                                </template>
                                                <template v-else>
                                                    <option v-for="(obj, index) in reference.digital_source.ether" :value="obj.value">{{obj.text}}</option>
                                                </template>

                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-sm-1" v-if="!readonly">
                                        <nobr>{{$t('main.operator')}}:</nobr>
                                    </div>
                                    <div class="col-sm-3" v-if="!readonly">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="order.user_name" disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-1" v-if="!readonly">
                                        <nobr>{{$t('main.operator_opp')}}:</nobr>
                                    </div>
                                    <div class="col-sm-3" v-if="!readonly">
                                        <input type="text" class="form-control" v-model="order.opp_name" disabled>
                                    </div>

                                </div>
                                <br/>
                            </template>

                            <template v-if="rule_tv_admin || rule_opp">
                                <div class="row" >
                                    <div class="col-sm-1">
                                        <nobr>{{$t('helpdesk.entity.site')}}:</nobr>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="input-group">
                                            <select class="form-control" v-model="order.site" id="site" ref="site" tabindex="7" :disabled="readonly">
                                                <option value="hopshop-uz">Hopshop</option>
                                                <option value="zargarshop">ZargarShop</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!--div class="col-sm-8">
                                        <template v-if="typeof order.customer.club_card !== 'undefined' && order.customer.club_card!=''">
                                            <span class="text-success">Card valid until {{order.customer.club_card}}</span>
                                        </template>
                                    </div-->
                                    <template v-if="typeof order.customer.customer_id !== 'undefined'">
                                        <div class="col-sm-1">{{$t('main.customer')}}: </div>
                                        <div class="col-sm-3">
                                            <button class="btn" @click="showCustomer(order.customer.customer_id)">
                                                {{order.customer.name}}
                                                <template v-if="typeof order.customer.club_card !== 'undefined' && order.customer.club_card!=''">
                                                    (Card valid until {{ order.customer.club_card }})
                                                </template>
                                            </button>
                                            <div class="btn-square btn-square_cancel" role="button" @click="resetCustomer"> </div>
                                        </div>
                                        <template v-if="typeof order.customer.coupons !== 'undefined' && order.customer.coupons.length > 0">
                                            <div class="col-sm-1">Coupons:</div>
                                            <div class="col-sm-3">
                                                <b-form-group v-slot="{ ariaDescribedby }">
                                                    <b-form-checkbox
                                                            v-for="coupon in order.customer.coupons"
                                                            v-model="coupon.select"
                                                            :key="coupon.value"
                                                            :value="coupon.value"
                                                            :aria-describedby="ariaDescribedby"
                                                            :disabled="coupon.disabled"
                                                            inline
                                                            >
                                                        <span v-b-tooltip.hover :title="coupon.title">{{ coupon.text }}</span>
                                                    </b-form-checkbox>
                                                </b-form-group>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-sm-4"></div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="col-sm-8"></div>
                                    </template>
                                </div>
                                <br/>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-sm-6">
                                    {{$t('order.form.title.order_list')}}
                                </div>
                                <div class="col-sm-6 text-right">
                                    <b-form inline style="display: block">
                                        <b-form-select v-if="ether_id > 0" v-model="ether_id" :options="ethers" disabled style="margin-right: 10px"></b-form-select>
                                        <b-button class="btn btn-success" @click="showModal()" :disabled="readonly">
                                            {{$t('order.form.data.add_product')}}
                                        </b-button>
                                    </b-form>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <table class="table" id="cart">
                                <thead>
                                <tr>
                                    <th style="width: 10%">N</th>
                                    <th style="width: 10%">{{$t('main.picture')}}</th>
                                    <th style="width: 35%">{{$t('main.product')}}</th>
                                    <th style="width: 20%">{{$t('main.price')}}</th>
                                    <th style="width: 10%">{{$t('main.quantity')}}</th>
                                    <th style="width: 10%">{{$t('main.amount')}}</th>
                                    <th style="width: 5%"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in order.cart" :key="item.id">
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        <ExpandImage thumbnail fluid :src="item.image" width="80"></ExpandImage>
                                    </td>
                                    <td>
                                        <!--template v-if="item.stock >= item.reserved"-->
                                            {{ item.name }}
                                      <template v-if="item.not_use_discount=='true'">
                                        <b-icon-shield-x></b-icon-shield-x>
                                      </template>
                                        <!--/template-->
                                        <!--template v-else>
                                            {{ item.name }} <b-icon-exclamation-diamond variant="danger" title="Недостаточно для продажи"></b-icon-exclamation-diamond>
                                        </template-->
                                    </td>
                                    <td class="text-center">
                                        <template v-if="order.status == 'new-order'">
                                            <input type="number" min="0"  class="text-center" v-model="item.amount" :disabled="readonly">
                                        </template>
                                        <template v-else>
                                            {{ item.amount | formatNumber }} {{currency}}
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        <input type="number" min="1" :max="item.stock" style="width: 50px;" class="text-center" @change="changeQuantity(item, $event)" v-model="item.quantity" :disabled="readonly">
                                    </td>
                                    <td class="text-center">
                                        {{ item.total | formatNumber }} {{currency}}
                                    </td>
                                    <td class="text-center">
                                        <b-button class="btn btn-sm btn-primary" @click="deleteProduct(item)" :disabled="readonly">
                                            <b-icon-trash></b-icon-trash>
                                        </b-button>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-if="rules.discount">
                                    <td colspan="6" class="text-right">Разовая скидка: <input type="number" min="0" class="text-right" v-model="order.discount_cash" :disabled="readonly"> {{currency}}
                                        <input type="number" min="0" max="20" class="text-right" v-model="order.discount_percent" :disabled="readonly"> %
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            <div class="row" style="border-top: 1px solid rgba(0, 0, 0, 0.1);padding-top: 20px">
                                <div class="col-sm-8">
                                </div>
                                <div class="col-sm-4">
                                    <table style="width: 100%">
                                        <tr>
                                            <td>{{$t('order.form.data.cost_of_goods')}}:</td>
                                            <td class="text-right">{{ totalProducts | formatNumber }} {{currency}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('order.form.data.amount_of_discounts_per_order')}}:</td>
                                            <td class="text-right">{{ totalDiscount | formatNumber }} {{currency}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('order.form.data.amount_of_discounts_per_coupon')}}:</td>
                                            <td class="text-right">{{ totalCoupone | formatNumber }} {{currency}}</td>
                                        </tr>
                                        <tr>
                                            <td>{{$t('order.form.data.cost_of_delivery')}}:</td>
                                            <td class="text-right">{{ order.delivery_cost | formatNumber }} {{currency}}</td>
                                        </tr>
                                        <tr>
                                            <td><h3>{{$t('order.form.data.total')}}:</h3></td>
                                            <td class="text-right"><h3>{{ totalOrder | formatNumber }} {{currency}}</h3></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-sm-7">
                    <div class="card">
                        <div class="card-header">
                            {{$t('order.form.title.shipment_and_delivery')}}
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-3">
                                    <nobr>{{$t('order.form.data.free_shipping')}}: *</nobr>
                                </div>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <select class="form-control" v-model="order.delivery_cost" id="delivery_cost" ref="delivery_cost" required tabindex="10" @change="resetError($event)" :disabled="readonly">
                                            <option :value="delivery_cost">{{$t('order.form.data.no')}}</option>
                                            <option value="0">{{$t('order.form.data.yes')}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br/>
                            <div class="row">
                                <div class="col-sm-3">
                                    <nobr>{{$t('main.store')}}: *</nobr>
                                </div>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <b-form-select v-if="order.site=='hopshop-uz'" v-model="order.warehouse" :options="options_store_hs" :disabled="readonly" id="warehouse" ref="warehouse"></b-form-select>
                                        <b-form-select v-else v-model="order.warehouse" :options="options_store_zr" :disabled="readonly" id="warehouse" ref="warehouse"></b-form-select>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br/>
                            <template v-if="country=='pl'">
                                <div class="row" >
                                    <div class="col-sm-3">
                                        {{$t('order.form.data.postcode')}}: *
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="order.postcode" id="postcode" ref="postcode"
                                                   autocomplete="off" required tabindex="11"
                                                   @change="resetError($event)" :disabled="readonly">
                                            <div class="invalid-feedback">
                                                {{$t('order.form.data.you_missed_this_field')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                            </template>
                            <div class="row">
                                <div class="col-sm-3">
                                    <nobr>{{$t('order.form.data.region')}}: *</nobr>
                                </div>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <select class="form-control" v-model="order.region" id="region" ref="region" tabindex="11" @change="resetError($event)" :disabled="readonly">
                                            <option v-for="(item, index) in regions" :key="item.value">
                                                {{item.text}}
                                            </option>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br/>
                            <div class="row">
                                <div class="col-sm-3">
                                    <nobr>{{$t('order.form.data.city')}}: *</nobr>
                                </div>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <cityForm
                                                id="city"
                                                ref="city"
                                                :isError="city_error"
                                                :region="order.region"
                                                :city="order.city"
                                                :tabindex="12"
                                                @result="setCity"
                                                @setValue="setValue"
                                                :disabled="readonly"
                                                >
                                        </cityForm>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                          <div v-if="order.city_obj?.is_mountainous_region" class="row mt-1">
                            <div class="col-sm-3">
                              <b-badge show pill class="" variant="danger" :disabled="readonly">
                                Горная местность
                              </b-badge>
                            </div>
                            <div class="col-sm-8">
                              <div class="">
                                <p style="font-size: small; position: absolute; top: 10%" class="mb-0">Доставка может задержаться</p>
                              </div>
                            </div>
                          </div>
                            <br/>
                            <div class="row">
                                <div class="col-sm-3">
                                    <nobr>{{$t('order.form.data.address')}}: *</nobr>
                                </div>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <textarea class="form-control" rows="1" v-model="order.address" id="address" ref="address"
                                            autocomplete="off" required tabindex="13"
                                            @change="resetError($event)" :disabled="readonly">
                                        </textarea>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div class="row" v-if="show_delivery_date">
                                <div class="col-sm-3">
                                    <nobr>{{$t('order.form.data.delivery_date')}}: *</nobr>
                                </div>
                              <div class="col-sm-9">
                                <b-datepicker v-model="order.delivery_date" :min="min_date_delivery" :max="max_date_delivery" :disabled="order.region === '' || readonly" ></b-datepicker>
                              </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
                </div>
                <div class="col-sm-5">
                    <div class="card">
                        <div class="card-header">
                            {{$t('order.form.title.payment')}}
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-3">
                                    <nobr>{{$t('order.form.data.payment_type')}}: *</nobr>
                                </div>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <select class="form-control" v-model="order.typePayment" id="typePayment" ref="typePayment" tabindex="14" @change="resetError($event)" :disabled="readonly">
                                            <option v-for="(obj, index) in reference.payment_type" :value="obj.value">{{obj.text}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br/>
                            <div class="row">
                                <div class="col-sm-3">
                                    <nobr>{{$t('order.form.data.prepayment')}}: *</nobr>
                                </div>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <select class="form-control" v-model="order.prepayment" id="prepayment" ref="prepayment" tabindex="15" @change="resetError($event)" :disabled="readonly">
                                            <option v-for="(obj, index) in reference.prepayment" :value="obj.value">{{obj.text}}</option>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br/>
                            <template v-if="order.prepayment=='1'">
                                <div class="row">
                                    <div class="col-sm-3">
                                        {{$t('order.form.data.prepayment_value')}}: *
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="input-group">
                                            <input type="number" class="form-control" v-model="order.prepayment_value" id="prepayment_value" ref="prepayment_value"
                                                   min="0"
                                                   autocomplete="off" required tabindex="17"
                                                   @change="resetError($event)">
                                            <div class="invalid-feedback">
                                                {{$t('order.form.data.you_missed_this_field')}}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br/>
                                <div class="row">
                                    <div class="col-sm-3">
                                        {{$t('order.form.data.prepayment_service')}}: *
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="input-group">
                                            <select class="form-control" v-model="order.prepayment_service" id="prepayment_service" ref="prepayment_service" tabindex="18"
                                                    @change="resetError($event)">
                                                <option v-for="(item, index) in reference.prepayment_service" :key="item.value" :value="item.value">
                                                    {{item.text}}
                                                </option>
                                            </select>
                                            <div class="invalid-feedback">
                                                {{$t('order.form.data.you_missed_this_field')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div class="row">
                                    <div class="col-sm-3">
                                        {{$t('order.form.data.prepayment_phone')}}: *
                                    </div>
                                    <div class="col-sm-9">
                                        <div class="input-group">
                                            <input type="number" class="form-control" v-model="order.prepayment_phone" id="prepayment_phone" ref="prepayment_phone"
                                                   min="0"
                                                   autocomplete="off" required tabindex="17"
                                                   @change="resetError($event)">
                                            <div class="invalid-feedback">
                                                {{$t('order.form.data.you_missed_this_field')}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                            </template>
                            <div class="row">
                                <div class="col-sm-3">
                                    <nobr>{{$t('order.form.data.discount_promotional_code')}}: *</nobr>
                                </div>
                                <div class="col-sm-9">
                                    <div class="input-group">
                                        <select class="form-control" v-model="order.discount" id="discount" ref="discount" tabindex="16"
                                                @change="resetError($event)" :disabled="readonly || disabled_discount">
                                            <template v-if="order.site == 'zargarshop'">
                                                <option v-for="(obj, index) in reference.discount.ether" :value="obj.value" :data-discount="obj.data">{{obj.text}}</option>
                                            </template>
                                            <template v-else>
                                                <option v-for="(obj, index) in reference.discount.free" :value="obj.value" :data-discount="obj.data">{{obj.text}}</option>
                                            </template>
                                        </select>
                                        <div class="invalid-feedback">
                                            {{$t('order.form.data.you_missed_this_field')}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <br/>
                    <div class="card">
                        <div class="card-header">
                            <nobr>{{$t('order.form.title.operator_comments')}}</nobr>
                        </div>
                        <div class="card-body">
                            <textarea class="form-control" rows="3" v-model="order.commentOperator" tabindex="17" :disabled="readonly"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <addProductEther
                    :ether_id="ether_id"
                    :details="details"
                    :skip_search="skip_search"
                    :site="order.site"
                    @close="hideModal"
                    @addProduct="addProduct"
                    @delProduct="delProduct"
                    >
            </addProductEther>
            <addProduct
                    :details="details"
                    :site="order.site"
                    @close="hideModal"
                    @addProduct="addProduct"
                    @delProduct="delProduct"
                    >
            </addProduct>
        </b-overlay>
        </b-modal>
        <!--Customer
                :bus="localbus_c"
                >
        </Customer-->
    </div>
</template>

<style>
    .modal-xl {
        max-width: 1600px !important;
    }
</style>

<script>
    import Axios from 'axios';
    import bus from "../bus";
    import Vue from 'vue';
    import addProductEther from "@/components/addProductEther.vue";
    import addProduct from "@/components/addProduct.vue";
    import cityForm from "@/components/cityForm.vue";
    import phoneForm from "@/components/PhoneForm.vue";
    import ExpandImage from "@/components/ExpandImage.vue";
//    import Customer from "@/components/Customer.vue";

    export default {
        name: 'ComponentOrder',
        components: {
            addProduct,
            addProductEther,
            cityForm,
            ExpandImage,
//            Customer,
            phoneForm
        },

        data: function () {
            return {
                alerts:             [],                 // Массив сообщений
                loading:            false,
                rules: {
                    discount:       false               // права на скидки
                },
                products:           [],                 // массив товаров
                order: {
                    method: 	      'save',
                    ether_id:         0,
                    order_id:         0,
                    manager:          '',                 // Имя менеджера
                    typeOrder:        'hsm-sale',         // тип заказа
                    site:             'zargarshop',       // название магазина
                    customer:         {
                        customer_id:    undefined,
                        name:           '',
                        club_card:      '',
                        coupons:        []
                    },
                    lastName:         '',                 // фамилия клиента
                    firstName:        '',                 // имя клиента
                    sex:              '',                 // Пол клиента
                    birthdate:        '',                 // Дата рождения
                    phone:            '',                 // телефон клиента
                    additionalPhone:  '',                 // дополнительный телефон клиента
                    source:           '',
                    warehouse:        'sklad-iuvelirka',    // склад отгрузки
                    postcode:         '',
                    region:           '',                 // регион доставки
                    city:             '',                 // город доставки
                    city_obj:         null,               // объект города city_id, city_name, area_name, region_name
                    address:          '',                 // адрес доставки
                    typePayment:      'cash',             // тип оплаты
                    prepayment:       '0',                // наличие предоплаты
                    prepayment_value: '',                 // сумма предоплаты
                    prepayment_service:'',                // идентификатор платежной системы
                    prepayment_phone: '',                 // номер телефона для счета
                    discount:         'no_discount',      // вариант скидки
                    commentOperator:  '',                 // комментарий оператора
                    discount_cash:    0,                  // Поле скидки по сумме
                    discount_percent: 0,                  // Поле скидки по проценту
                    total_product:    0,                  // сумма по товарам
                    delivery_cost:    0,              // стоимость доставки
                    coupone_cost:     0,                  // скидка по купонам
                    cart:             [],                  // корзина
                    coupones:         [],
                    delivery_date:    '',
                    status:           '',
                    number:           '',
                    revision:         false,
                    express_delivery: false,              // Экспресс доставка
                },
                city_error:             false,                       // флаг ошибки в городе, прокидывается в компонент
                disabled_send_form:     false,
                disabled_reserve_form:  true,             // показать кнопку резерва
                disabled_discount:      false,
                details:                true,
                skip_search:            true,
                local_ethers:           [],
                ethers: [
                    { value: 0, text: this.$t('order_lots.form.data.please_make_a_choice') },
                    { value: -1, text: this.$t('order_lots.form.data.selling_from_a_catalog') }
                ],
                options_delivery_date: [],
                options_store_hs: [
                    {value: 'hopshop-andizhan', text: 'HSM Андижан' },
                    {value: 'hopshop-bukhara', text: 'HSM Бухара' },
                    {value: 'hopshop-dzhizak', text: 'HSM Джизак' },
                    {value: 'hopshop-karshi', text: 'HSM Карши' },
                    {value: 'hopshop-namangan', text: 'HSM Наманган' },
                    {value: 'hsm-nukus', text: 'HSM Нукус' },
                    {value: 'hopshop-samarkand', text: 'HSM Самарканд' },
                    {value: 'hsm-tashkent', text: 'HSM Ташкент' },
                    {value: 'hopshop-termez', text: 'HSM Термез' },
                    {value: 'hopshop-fergana', text: 'HSM Фергана' },
                    {value: 'fulfilment-fargo', text: 'Fulfilment FARGO' },
                ],
                options_store_zr: [
                    {value: 'sklad-iuvelirka', text: 'Склад Ювелирка' }
                ],
                delivery_cost: 0,
                so: {
                    options_status: []
                },
                ether_id: 0,
                order_id: 0,
                readonly: false,
                size: 20,
                localbus_p: new Vue(),
                min_date_delivery: '',
                max_date_delivery: ''
            }
        },

        props: ['bus' ],

        mounted() {
            this.ether_id = 0;

            this.delivery_cost          = this.getDeliveryCost();
            this.order.delivery_cost    = this.delivery_cost;

            this.bus.$on("show-order", (data) => {
                if (data.order_id != 0) this.loadOrder(data.ether_id, data.order_id);
                this.ether_id = this.order.ether_id = data.ether_id;
                this.order_id = this.order.order_id = data.order_id;

                if (typeof data.readonly !== "undefined") {
                  this.readonly = data.readonly;
                }else this.readonly = false;

                console.log('ORDER e: ' + this.ether_id+' o: '+this.order_id + ' r: '+this.readonly);
            });
            document.addEventListener('click', this.handleClickOutside);
        },

        beforeRouteLeave (to, from, next) {
            this.destroy();
            next();
        },

        methods: {
            destroy() {
                this.close();
                document.removeEventListener('click', this.handleClickOutside);
                this.bus.$off('show-order');
            },

            showModalHistory(order) {
                bus.$emit('portal:order:history:open', order);
            },

            loadOrder(ether_id, order_id) {
                this.loading = true;
                this.$root.$emit('bv::show::modal', 'bv-modal-show-order');
                Axios
                   .post(this.prefix_url + '/api/opp?ts=' + Date.now(), {
                            method:     'load',
                            ether_id:   ether_id,
                            order_id:   order_id,
                            tz:         Intl.DateTimeFormat().resolvedOptions().timeZone
                   }).then(response => {
                        this.order              = response.data.order;
                        this.so.options_status  = response.data.statuses;

                        this.loadEthers(ether_id);

                        if (this.order.city_obj.city_id == 0) this.city_error = true;else this.city_error = false;

//                        this.calc_delivery_period();
                        this.loading = false;

                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                this.localbus_p.$emit('set_phone', this.order.phone);
                            });
                        });

                });
            },

            addProduct(product) {
                let index = this.order.cart.map(function (item) {
                    return item.id;
                }).indexOf(product.id);
                if (index == -1) {
                    let obj = {
                        id:       product.id,
                        image:    product.image,
                        name:     product.name,
                        amount:   parseInt(product.amount),
                        quantity: parseInt(product.quantity),
                        total:    (parseInt(product.amount) * parseInt(product.quantity)),
                        stock:    parseInt(product.stock),
                        not_use_discount: product.not_use_discount,
                        reserved: parseInt(product.reserved),
                    };
                    this.createReserve(obj);

                    this.order.cart.push(obj);
                } else {
                    this.order.cart[index].quantity = product.quantity;
                    this.updateReserve(this.order.cart[index]);
                }
            },

            deleteProduct(product) {
                if (confirm(this.$t('order.form.data.are_you_sure_you_want_to_delete'))) {
                    bus.$emit('delete-product', { id: product.id });
                    this.delProduct(product);
                }
            },

            delProduct(product) {
                this.removeReserve(product);    // удаляем резерв
                let index = this.order.cart.map(function (item) {
                    return item.id;
                }).indexOf(product.id);
                this.order.cart.splice(index, 1);
            },

            submitForm(flag_reserve = false) {
                let el = '';

                if (this.order.phone === '') {
                    if (el === '') el = 'phone';
                    this.$refs.phone.classList.value = this.$refs.phone.classList.value + " is-invalid";
                }
                if (this.order.firstName === '') {
                    if (el === '') el = 'firstName';
                    this.$refs.firstName.classList.value = this.$refs.firstName.classList.value + " is-invalid";
                }
                if (this.order.sex === '') {
                    if (el === '') el = 'sex';
                    this.$refs.sex.classList.value = this.$refs.sex.classList.value + " is-invalid";
                }

                if (this.order.region === '') {
                    if (el === '') el = 'region';
                    this.$refs.region.classList.value = this.$refs.region.classList.value + " is-invalid";
                }
                if (this.order.city === '') {
                    if (el === '') el = 'city';
                    this.city_error = true;
                }

                if (this.order.address === '') {
                    if (el === '') el = 'address';
                    this.$refs.address.classList.value = this.$refs.address.classList.value + " is-invalid";
                }
                if (this.country == 'pl' && this.order.postcode === '') {
                    if (el === '') el = 'postcode';
                    this.$refs.postcode.classList.value = this.$refs.postcode.classList.value + " is-invalid";
                }
                if (this.order.typePayment === '') {
                    if (el === '') el = 'typePayment';
                    this.$refs.typePayment.classList.value = this.$refs.typePayment.classList.value + " is-invalid";
                }
                if (this.order.prepayment === '') {
                    if (el === '') el = 'prepayment';
                    this.$refs.prepayment.classList.value = this.$refs.prepayment.classList.value + " is-invalid";
                }
                if (this.order.prepayment === '1' && this.order.prepayment_value === '') {
                    if (el === '') el = 'prepayment_value';
                    this.$refs.prepayment_value.classList.value = this.$refs.prepayment_value.classList.value + " is-invalid";
                }

                if (this.order.prepayment === '1' && this.order.prepayment_service === '') {
                    if (el === '') el = 'prepayment_service';
                    this.$refs.prepayment_service.classList.value = this.$refs.prepayment_service.classList.value + " is-invalid";
                }

                if (this.order.prepayment === '1' && this.order.prepayment_phone === '') {
                    if (el === '') el = 'prepayment_phone';
                    this.$refs.prepayment_phone.classList.value = this.$refs.prepayment_phone.classList.value + " is-invalid";
                }

                if (this.order.discount === '') {
                    if (el === '') el = 'discount';
                    this.$refs.discount.classList.value = this.$refs.discount.classList.value + " is-invalid";
                }

        if (this.order.warehouse === '') {
            if (el === '') el = 'warehouse';
            this.$refs.warehouse.classList.value = this.$refs.warehouse.classList.value + " is-invalid";
        }

                if (el != '') {
                    this.alerts.push(this.$t('order.form.data.errors_were_found_correct_them_and_save_the_order_again'));
                    bus.$emit('view-message', { message: this.$t('order.form.data.errors_were_found_correct_them_and_save_the_order_again'), variant: 'danger' });
                    this.focusRef(this.$refs[el]);
                    return;
                } else {
                    if (this.order.cart.length === 0) {
                        if (el === '') el = 'deliverydate';
                        this.alerts.push(this.$t('order.form.data.empty_cart_add_items_to_order'));
                        bus.$emit('view-message', { message: this.$t('order.form.data.empty_cart_add_items_to_order'), variant: 'danger' });
                        this.focusRef(this.$refs[el]);
                        return;
                    }

                    if (!this.ValidPhone(this.order.phone)) {
                        if (el === '') el = 'phone';
                        this.$refs.phone.classList.value = this.$refs.phone.classList.value + " is-invalid";
                        this.alerts.push(this.$t('order.form.data.invalid_phone_number_format'));
                        bus.$emit('view-message', { message: this.$t('order.form.data.invalid_phone_number_format'), variant: 'danger' });
                        this.focusRef(this.$refs[el]);
                        return;
                    }

                    if (this.order.birthdate!='' && !this.ValidDate(this.order.birthdate)) {
                        if (el === '') el = 'birthdate';
                        this.$refs.birthdate.classList.value = this.$refs.birthdate.classList.value + " is-invalid";
                        this.alerts.push(this.$t('order.form.data.invalid_date_of_birth_format'));
                        bus.$emit('view-message', { message: this.$t('order.form.data.invalid_date_of_birth_format'), variant: 'danger' });
                        this.focusRef(this.$refs[el]);
                        return;
                    }

                    if (this.order.additionalPhone != '' && !this.ValidPhone(this.order.additionalPhone)) {
                        if (el === '') el = 'additionalPhone';
                        this.$refs.additionalPhone.classList.value = this.$refs.additionalPhone.classList.value + " is-invalid";
                        this.alerts.push(this.$t('order.form.data.invalid_phone_extension_format'));
                        bus.$emit('view-message', { message: this.$t('order.form.data.invalid_phone_extension_format'), variant: 'danger' });
                        this.focusRef(this.$refs[el]);
                        return;
                    }

                    if (this.order.additionalPhone != '' && this.order.additionalPhone == this.order.phone) {
                        if (el === '') el = 'additionalPhone';
                        this.$refs.additionalPhone.classList.value = this.$refs.additionalPhone.classList.value + " is-invalid";
                        this.alerts.push(this.$t('order.form.data.the_extension_number_must_not_be_equal_to_the_main_phone_number'));
                        bus.$emit('view-message', { message: this.$t('order.form.data.the_extension_number_must_not_be_equal_to_the_main_phone_number'), variant: 'danger' });
                        this.focusRef(this.$refs[el]);
                        return;
                    }
                }
                this.order.manager = this.userName;
                this.order.ether_id = this.ether_id;
                this.order.accept_reserved = flag_reserve;

                let data = JSON.stringify(this.order);
                this.loading = true;
                Axios
                    .post(this.prefix_url + '/api/opp/?ts=' + Date.now(), this.order)
                    .then(res => {
                        this.disabled_send_form = false;

                        if (res.data.success) {
                            this.loadOrder(this.ether_id, this.order_id);
                            bus.$emit('view-message', { message: this.$t('main.order_was_successfully_changed'), variant: 'success' });
                            this.disabled_reserve_form = true;
                        } else {
                            bus.$emit('view-message', { message: res.data.message, variant: 'danger' });

                            if (res.data.flag_reserved) {
                                this.disabled_reserve_form = false;
                            }
                        }
                        this.loading = false;
                }).catch(error => console.log(error));
            },

            cancelForm() {
                this.close(true);
            },

            changeQuantity(item, e) {
                this.updateReserve(item);     // изменяем резерв
            },

            showModal() {
                if (this.ether_id > 0) {
                    this.$root.$emit('bv::show::modal', 'modaladdProductEther');
                    bus.$emit('update-ether', this.ether_id);
                }else{
                    this.$root.$emit('bv::show::modal', 'modaladdProduct');
                }
            },

            hideModal() {
                if (this.ether_id > 0) {
                    this.$root.$emit('bv::hide::modal', 'modaladdProductEther');
                }else{
                    this.$root.$emit('bv::show::modal', 'modaladdProduct');
                }
            },

            ValidPhone(phone) {
                let str = phone.replace(/\D/g, '');
                var re = /^\d{9}$/;
                if (typeof process.env.VUE_APP_COUNTRY === 'undefined' || process.env.VUE_APP_COUNTRY == 'uz' || process.env.VUE_APP_COUNTRY == 'pl') re = /^\d{9}$/;
                else if (process.env.VUE_APP_COUNTRY == 'kz') re = /^\+?7?\d{10}$/;
                else re = /^\+?7?\d{10}$/;

                return re.test(phone);
            },

            ValidDate(date){
                var re = /^\d{4}-\d{2}-\d{2}$/;
                return re.test(date);
            },

            focusRef(ref) {
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        ;(ref.$el || ref).focus()
                    })
                })
            },

            resetError(e) {
                let el = e.target.id;
                this.$refs[el].classList.value = "form-control";
                if (this.alerts.length > 0) this.alerts = [];

                if (el == 'region') {
                    this.order.city = '';
                    this.order.city_obj = null;
                    this.order.delivery_date = '';
                    this.calc_delivery_period();
                }
                if (el == 'discount') {
                    if(e.target.options.selectedIndex > -1)
                        this.order.discount_percent = parseInt(e.target.options[e.target.options.selectedIndex].dataset.discount);
                }
                if (el == 'prepayment_value'){
                    if (this.order.prepayment_value > this.totalOrder){
                        this.order.prepayment_value = this.totalOrder;
                    }
                }
                if (this.order.express_delivery && this.order.delivery_cost == 0) this.order.express_delivery = false;
            },

            changeExpress(e) {
                this.delivery_cost =   this.getDeliveryCost();
                this.order.delivery_cost = this.delivery_cost;
            },

            setValue(value){
                this.order.city     = value;
                this.order.city_obj = null;
            },

            calc_delivery_period(){
                let period = (this.getDeliveryPeriod(this.order.region));
                let date = new Date();
                let expr = date.setDate(date.getDate() + period );
                console.log('min', new Date(expr), period);
                this.min_date_delivery =  new Date(expr);

                let date2 = new Date();
                expr = date2.setDate(date2.getDate() + period + 2);
                console.log('max', new Date(expr));
                this.max_date_delivery = new Date(expr);

//                if ((this.order.city_obj !== null && typeof this.order.city_obj.city_id !== 'undefined' && this.order.city_obj.city_id != 507730 && this.order.express_delivery) || (this.order.city_obj == null || typeof this.order.city_obj.city_id == 'undefined') ) this.order.express_delivery = false;
//                this.delivery_cost =   this.getDeliveryCost();
//                this.order.delivery_cost = this.delivery_cost;
            },

            setCity(city) {
                this.order.city_obj = city;
                this.order.city     = city.city_name;
                this.order.region   = city.region_name;
                this.city_error     = false;
                this.calc_delivery_period();

                this.delivery_cost        = this.getDeliveryCost();
                this.order.delivery_cost  = this.delivery_cost;
            },

            getDateToday(){
                return this.getDatePeriod(0);
            },

            getDateTomorrow(){
                return this.getDatePeriod(1);
            },

            getDatePeriod(day){
                let today = new Date(Date.now() + 86400000 * day);
                let dd = String(today.getDate()).padStart(2, '0');
                let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                let yyyy = today.getFullYear();
                return yyyy + '-' + mm + '-' + dd;
            },

            getSecondsToday() {
                let now = new Date();

                let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

                let diff = now - today; // разница в миллисекундах
                return Math.round(diff / 1000); // получаем секунды
            },

            getDeliveryPeriod(region) {
                let loc = this.$store.getters.getLocal;
                let regions = loc.regions;
                let obj = regions.find(o => o.text === region);
                if (typeof obj !== 'undefined') return obj.delivery_period;

                return 1;
            },

            getDeliveryCost() {
                let loc = this.$store.getters.getLocal;
                let dc  = loc.cost_by_city;

//                if ()
                if (this.order.express_delivery) return 49000;

                if (this.order.city_obj !== null && typeof this.order.city_obj.city_id !== 'undefined') {
                    let obj = dc.find(o => o.city_id == this.order.city_obj.city_id);
                    if (typeof obj !== 'undefined') return obj.cost;
                }
                return loc.delivery_cost;
            },


            close(removeReserve = false){
                let _this = this;
// удаление резерва
                if (removeReserve) {
                    this.order.cart.forEach(function (item) {
                        if (typeof item.reserve_id !== 'undefined') {
                            _this.removeReserve(item);
                        }
                    });
                }
                this.order.manager          = '';
                this.order.typeOrder        = '';
                this.order.site             = '';
                this.order.lastName         = '';
                this.order.firstName        = '';
                this.order.sex              = '';
                this.order.birthdate        = '';
                this.order.phone            = '';
                this.order.additionalPhone  = '';
                this.order.source           = '';
                this.order.warehouse        = '';
                this.order.postcode         = '';
                this.order.region           = '';
                this.order.city             = '';
                this.order.city_obj         = null;
                this.order.address          = '';
                this.order.typePayment      = 'cash';
                this.order.prepayment       = '0';
                this.order.discount         = 'no_discount';
                this.order.commentOperator  = '';
                this.order.discount_cash    = 0;
                this.order.discount_percent = 0;
                this.order.total_product    = 0;
                this.order.delivery_cost    = this.delivery_cost;
                this.order.coupone_cost     = 0;
                this.order.cart             = [];
                this.order.coupones         = [];
                this.order.revision         = false;
                this.order.express_delivery = false;
                this.order.customer         = {
                    customer_id: undefined,
                    name: '',
                    club_card: '',
                    coupons: []
                };
                this.$root.$emit('bv::hide::modal', 'bv-modal-show-order');

                this.$emit('close');
            },

            createReserve(item) {
                let _this = this;
                Axios
                    .post(this.prefix_url + '/api/reserve/', {
                        method: 'create_reserve',
                        ether_id: this.ether_id,
                        offer_id: item.id,
                        count: item.quantity
                    })
                    .then(res => {
                        if (res.data.success) {
                            item.reserve_id = res.data.reserve_id;
                            let _stock = res.data.stock,
                                _reserve = res.data.reserved;
                            item.stock = _stock;
                            item.reserved = _reserve;
                            if (_reserve > _stock) bus.$emit('view-message', { message: this.$t('order_lots.form.data.the_product_is_reserved_but_the_quantity_is_not_enough_to_complete_the_sale'), variant: 'danger' });
                        } else {
                            bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                        }
                }).catch(error => console.log(error));
            },

            updateReserve(item){
                let _this = this;

                if (typeof item.reserve_id === 'undefined') return;

                Axios
                    .post(this.prefix_url + '/api/reserve/', {
                        method: 'change_reserve',
                        reserve_id: item.reserve_id,
                        count: item.quantity
                    })
                    .then(res => {
                        if (res.data.success) {
                            let _stock = res.data.stock,
                                _reserve = res.data.reserved;
                            item.stock = _stock;
                            item.reserved = _reserve;
                        } else {
                            bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                        }
                }).catch(error => console.log(error));
            },

            removeReserve(item){
                let _this = this;

                if (typeof item.reserve_id === 'undefined') return;

                Axios
                    .post(this.prefix_url + '/api/reserve/', {
                        method: 'delete_reserve',
                        reserve_id: item.reserve_id
                    })
                    .then(res => {
                        if (res.data.success) {
                        } else {
                            bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                        }
                }).catch(error => console.log(error));
            },

            loadEthers(ether_id){                  // загрузка данных с backend
                let _this = this;
                let def = true;
                Axios
                    .get(this.prefix_url + '/api/ethers?ts='+Date.now()+'&type=list&ether_id='+ether_id+'&tz='+Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .then(response => {
                        _this.local_ethers = response.data.ethers;
//console.log(_this.local_ethers);
                        let flag_skip_ether = true,
                            last_time = 0,
                            last_ether_id = 0;

                        _this.local_ethers.forEach(function(item){
                                    _this.ethers.push({
                                        value: item.id,
                                        text: item.number + ': ' + item.plan_from.replace(/([0-9]{4}-[0-9]{2}-[0-9]{2}) ([0-9]{2}:[0-9]{2}):[0-9]{2}/g, '$2') + ' - ' + item.plan_to.replace(/([0-9]{4}-[0-9]{2}-[0-9]{2}) ([0-9]{2}:[0-9]{2}):[0-9]{2}/g, '$2')
                                    });

                        });
//                        if (flag_skip_ether) _this.ether_id = last_ether_id;
                });
            },

            showCustomer(customer_id){
                bus.$emit('portal:customer:open', customer_id);
            },

            resetCustomer(){
                this.order.customer     = {
                    customer_id: undefined,
                    name: '',
                    club_card: '',
                    coupons: []
                };
                this.order.firstName    = '';
                this.order.lastName     = '';
                this.order.sex          = '';
                this.order.birthdate    = '';
                this.order.postcode     = '';
                this.order.city         = '';
                this.order.region       = '';
                this.order.city_obj     = {};
                this.order.address      = '';
            },

            setPhoneObj(result){
                console.log(result);
                this.order.customer   = result;
                this.order.firstName  = result.firstName;
                this.order.lastName   = result.lastName;
                this.order.phone      = result.phone;
                this.order.sex        = result.sex;
                this.order.birthdate  = result.birthdate;
                this.order.postcode   = result.postcode;
                this.order.address    = result.address;
//                if (this.order.prepayment_phone == '') this.order.prepayment_phone = result.phone;
                this.setCity({ city_id: result.city_id, city_name: result.city_name, area_name: result.area_name, region_name: result.region_name });
            },

            setPhone(val){
                this.order.phone = val;
//                this.order.prepayment_phone = val;
            },

            handleClickOutside(event) {
                if (event.target.id == 'bv-modal-show-order') {
                    this.close();
                }
            }
        },

        computed : {
            userName: function () {
                return this.$store.getters.getUserName
            },

            current_phone: {
                get () { return this.$store.getters.getCurrentPhone; },
                set(value) { this.$store.dispatch('setCurrentPhone', value); }
            },

            regions: function () {
                let loc = this.$store.getters.getLocal;
                return loc.regions;
            },

            currency: function () {
                let loc = this.$store.getters.getLocal;
                return loc.currency;
            },


            show_delivery_date: function () {
                let loc = this.$store.getters.getLocal;
                if (typeof loc.show_delivery_date === 'undefined') return false;
                return loc.show_delivery_date;
            },

            reference: function () {
                let loc = this.$store.getters.getLocal;
                if (typeof loc.reference === 'undefined') return {};
                return loc.reference;
            },

            totalProducts() {
                let total_product = 0;
                this.order.cart.forEach(function (item) {
                    item.total = parseFloat(item.amount) * parseFloat(item.quantity);
                    total_product += item.total;
                });
                return total_product;
            },

            totalDiscount() {
                let total_discount = 0, ret = 0;
//                if (this.order.prepayment == '1') total_discount = 5;
                if (typeof this.order.customer.club_card !== 'undefined' && this.order.customer.club_card!='') total_discount = 5;
                total_discount += parseFloat(this.order.discount_percent);
              this.order.cart.forEach(function (item) {
                if (item.not_use_discount!='true') ret += parseFloat(item.amount) * parseFloat(item.quantity) * (total_discount / 100);
              });
              return ret;
//                return parseFloat(this.order.discount_cash) + total_discount / 100 * this.totalProducts;
            },

            totalCoupone() {
                let
                        _this = this,
                        t1 = 0,
                        t2 = 0,
                        t3 = 0,
                        ret = 0;
// расчет суммы заказа без товаров которые нельзя давать скидку
                this.order.cart.forEach(function (item) {
                    if (item.not_use_discount!='true') t1 += parseFloat(item.amount) * parseFloat(item.quantity);
                });
// максимальный процент скидки
                t2 = t1 * 0.125;
                _this.order.coupones = [];
                if (typeof _this.order.customer.coupons !== 'undefined') {
                    _this.order.customer.coupons.forEach(function (item) {
                        t3 += parseInt(item.amount);
                        console.log('t3', t3);
                        if (t3 <= t2) item.disabled = false;
                        else {
                            item.disabled = true;
                            item.select = '';
                        }
                        if (item.select == item.value) {
                            ret += parseInt(item.amount);
                            _this.order.coupones.push(item.value);
                        }
                    });
                }
// отключение скидки при включении купонов
                if (_this.order.coupones.length > 0){
                    _this.disabled_discount = true;
                    _this.order.discount = 'no_discount';
                    _this.order.discount_percent = 0;
                }else _this.disabled_discount = false;

                _this.order.coupone_cost = ret;
                return ret;
            },

            totalOrder() {
                return this.totalProducts - this.totalDiscount + parseFloat(this.order.delivery_cost) - this.totalCoupone;
            },

            country: function () {
                if (typeof process.env.VUE_APP_COUNTRY === 'undefined') return '';
                return process.env.VUE_APP_COUNTRY;
            },

            rule_order_source: function (){return this.$store.getters.getRule('order_source') },
            rule_tv_admin: function (){return this.$store.getters.getRule('tv_admin') },
            rule_opp: function (){return this.$store.getters.getRule('opp') },
            prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
        },

        watch: {
            ether_id: function(val, old) {
                if (old != 0) {
                    this.order.ether_id = val;
                    if (this.order.cart.length > 0) {
                        this.order.cart = [];
//                    bus.$emit('view-message', {message: 'Вы изменили эфир, корзина будет очищена', variant: 'danger'});
                    }
                }
            },

            order: {
                handler(val) {
                    this.delivery_cost = this.getDeliveryCost();
                }
            }
        }
    };
</script>

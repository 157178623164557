<template>
  <div>
    <br/>
    <b-overlay :show="loading" rounded="sm">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
              {{ $t('order.form.title.client')}}
          </div>
          <div class="card-body">
            <br/>
            <div class="row">
              <div class="col-sm-1">
                {{$t('order.form.data.phone')}}: *
              </div>
              <div class="col-sm-3">
                  <phoneForm
                            id="phone"
                            ref="phone"
                            :tabindex="1"
                            :bus="localbus_p"
                            @result="setPhoneObj"
                            @setPhone="setPhone"
                  >
                  </phoneForm>
              </div>
              <div class="col-sm-1">
                  {{$t('order.form.data.name')}}: *
              </div>
              <div class="col-sm-3">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="order.firstName" id="firstName" ref="firstName"
                         autocomplete="off" required tabindex="2"
                         @change="resetError($event)">
                  <div class="invalid-feedback">
                    {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>
              <div class="col-sm-1">
                {{$t('order.form.data.surname')}}:
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control" v-model="order.lastName" autocomplete="off" tabindex="3">
              </div>
            </div>

            <br/>
            <div class="row">
              <div class="col-sm-1">
                {{$t('order.form.data.additional_phone')}}:
              </div>
              <div class="col-sm-3">
                <input type="text" class="form-control" v-model="order.additionalPhone" id="additionalPhone"
                       ref="additionalPhone" autocomplete="off" tabindex="4">
              </div>
              <div class="col-sm-1">
                {{$t('order.form.data.client_gender')}}: *
              </div>
              <div class="col-sm-3">
                <div class="input-group">
                  <select class="form-control" v-model="order.sex" id="sex" ref="sex" @change="resetError($event)"
                          tabindex="5">
                    <option value="male">{{$t('order.form.data.male')}}</option>
                    <option value="female">{{$t('order.form.data.female')}}</option>
                  </select>
                  <div class="invalid-feedback">
                      {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>
              <div class="col-sm-1">
                {{$t('order.form.data.date_of_birth')}}:
              </div>
              <div class="col-sm-3">
                <input type="date" class="form-control" v-model="order.birthdate" id="birthdate"
                       ref="birthdate" autocomplete="off" tabindex="6">
              </div>
            </div>
            <br/>
            <div class="row" v-if="rule_order_source">
                  <div class="col-sm-1">
                      {{$t('order.form.data.digital_source')}}:
                  </div>
                  <div class="col-sm-3">
                      <div class="input-group">
                          <select class="form-control" v-model="order.source" id="source" ref="source"
                                  tabindex="7">
                              <option v-for="(item, index) in reference.digital_source.free" :key="item.value" :value="item.value">
                                  {{item.text}}
                              </option>
                          </select>
                      </div>
                  </div>
                  <template v-if="typeof order.customer.customer_id !== 'undefined'">
                      <div class="col-sm-1">{{$t('main.customer')}}: </div>
                      <div class="col-sm-3">
                          <button class="btn" @click="showCustomer(order.customer.customer_id)">
                              {{order.customer.name}}
                              <template v-if="typeof order.customer.club_card !== 'undefined' && order.customer.club_card!=''">
                                (Card valid until {{ order.customer.club_card }})
                              </template>
                          </button>
                          <div class="btn-square btn-square_cancel" role="button" @click="resetCustomer"> </div>
                      </div>
                      <template v-if="typeof order.customer.coupons !== 'undefined' && order.customer.coupons.length > 0">
                          <div class="col-sm-1">Coupons:</div>
                          <div class="col-sm-3">
                              <b-form-group v-slot="{ ariaDescribedby }">
                                  <b-form-checkbox
                                          v-for="coupon in order.customer.coupons"
                                          v-model="coupon.select"
                                          :key="coupon.value"
                                          :value="coupon.value"
                                          :aria-describedby="ariaDescribedby"
                                          :disabled="coupon.disabled"
                                          inline
                                          >
                                      <span v-b-tooltip.hover :title="coupon.title">{{ coupon.text }}</span>
                                  </b-form-checkbox>
                              </b-form-group>
                        </div>
                      </template>
                      <template v-else>
                        <div class="col-sm-4"></div>
                      </template>
                  </template>
                  <template v-else>
                      <div class="col-sm-8"></div>
                  </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                {{$t('order.form.title.order_list')}}
              </div>
              <div class="col-sm-6 text-right">
                <b-button class="btn btn-success" @click="showModal()">
                  {{$t('order.form.data.add_product')}}
                </b-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table" id="cart">
              <thead>
              <tr>
                <th style="width: 10%">N</th>
                <th style="width: 10%">{{$t('main.picture')}}</th>
                <th style="width: 35%">{{$t('main.product')}}</th>
                <th style="width: 20%">{{$t('main.price')}}</th>
                <th style="width: 10%">{{$t('main.quantity')}}</th>
                <th style="width: 10%">{{$t('main.amount')}}</th>
                <th style="width: 5%"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in order.cart" :key="item.id">
                <td class="text-center">{{ index + 1 }}</td>
                <td><ExpandImage thumbnail fluid :src="item.image" width="90"></ExpandImage></td>
                <td>
                  {{ item.name }}
                  <template v-if="item.not_use_discount=='true'">
                    <b-icon-shield-x></b-icon-shield-x>
                  </template>
                </td>
                <td class="text-center">{{ item.amount | formatNumber }} {{currency}}</td>
                <td class="text-center"><input type="number" min="1" style="width: 50px;" class="text-center"
                                               :value="item.quantity" @change="changeQuantity(item, $event)"></td>
                <td class="text-center">{{ item.total | formatNumber }} {{currency}}</td>
                <td class="text-center">
                  <b-button class="btn btn-sm btn-primary" @click="deleteProduct(item)">
                    <b-icon-trash></b-icon-trash>
                  </b-button>
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr v-if="rules.discount">
                <td colspan="6" class="text-right">{{$t('order.form.data.one_time_discount')}}: <input type="number" min="0" class="text-right"
                                                                          v-model="order.discount_cash"> {{currency}} <input
                    type="number" min="0" max="20" class="text-right" v-model="order.discount_percent"> %
                </td>
              </tr>
              </tfoot>
            </table>
            <div class="row" style="border-top: 1px solid rgba(0, 0, 0, 0.1);padding-top: 20px">
              <div class="col-sm-9">
              </div>
              <div class="col-sm-3">
                <table style="width: 100%">
                  <tr>
                    <td>{{$t('order.form.data.cost_of_goods')}}:</td>
                    <td class="text-right">{{ totalProducts | formatNumber }} {{currency}}</td>
                  </tr>
                  <tr>
                    <td>{{$t('order.form.data.amount_of_discounts_per_order')}}:</td>
                    <td class="text-right">{{ totalDiscount | formatNumber }} {{currency}}</td>
                  </tr>
                    <tr>
                        <td>{{$t('order.form.data.amount_of_discounts_per_coupon')}}:</td>
                        <td class="text-right">{{ totalCoupone | formatNumber }} {{currency}}</td>
                    </tr>
                  <tr>
                    <td>{{$t('order.form.data.cost_of_delivery')}}:</td>
                    <td class="text-right">{{ order.delivery_cost | formatNumber }} {{currency}}</td>
                  </tr>

                  <tr>
                    <td><h3>{{$t('order.form.data.total')}}:</h3></td>
                    <td class="text-right"><h3>{{ totalOrder | formatNumber }} {{currency}}</h3></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-sm-4">
        <div class="card">
          <div class="card-header">
            {{$t('order.form.title.shipment_and_delivery')}}
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                {{$t('order.form.data.free_shipping')}}: *
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <select class="form-control" v-model="order.delivery_cost" id="delivery_cost" ref="delivery_cost"
                          required tabindex="10"
                          @change="resetError($event)">
                    <option :value="delivery_cost">{{$t('order.form.data.no')}}</option>
                    <option value="0">{{$t('order.form.data.yes')}}</option>
                  </select>
                  <div class="invalid-feedback">
                      {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>

            </div>
            <br/>
              <template v-if="country=='pl'">
                  <div class="row" >
                      <div class="col-sm-3">
                          {{$t('order.form.data.postcode')}}: *
                      </div>
                      <div class="col-sm-9">
                          <div class="input-group">
                              <input type="text" class="form-control" v-model="order.postcode" id="postcode" ref="postcode"
                                     autocomplete="off" required tabindex="11"
                                     @change="resetError($event)">
                              <div class="invalid-feedback">
                                  {{$t('order.form.data.you_missed_this_field')}}
                              </div>
                          </div>
                      </div>
                  </div>
                  <br/>
              </template>
            <div class="row">
              <div class="col-sm-3">
                  {{$t('order.form.data.region')}}: *
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <select class="form-control" v-model="order.region" id="region" ref="region" tabindex="11"
                          @change="resetError($event)" >
                    <option v-for="(item, index) in regions" :key="item.value">
                        {{item.text}}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                      {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-sm-3">
                  {{$t('order.form.data.city')}}: *
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <cityForm
                      id="city"
                      ref="city"
                      :isError="city_error"
                      :region="order.region"
                      :city="order.city"
                      :tabindex="12"
                      @result="setCity"
                      @setValue="setValue"
                  >
                  </cityForm>
                  <div class="invalid-feedback">
                      {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>

            </div>
            <div v-if="order.city_obj?.is_mountainous_region" class="row mt-1">
              <div class="col-sm-4">
              <b-badge show pill class=""variant="danger" >
                Горная местность
              </b-badge>
              </div>
              <div class="col-sm-8">
              <div class="">
                <p style="font-size: small; position: absolute; top: 10%" class="mb-0">Доставка может задержаться</p>
              </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-sm-3">
                  {{$t('order.form.data.address')}}: *
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <textarea class="form-control" rows="1" v-model="order.address" id="address" ref="address"
                            autocomplete="off" required tabindex="13"
                            @change="resetError($event)"></textarea>
                  <div class="invalid-feedback">
                      {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>
            </div>
<!--            <div v-if="options_delivery_date.length > 1">-->
<!--            <br/>-->
<!--            <div class="row" >-->
<!--                  <div class="col-sm-3">-->
<!--                      {{$t('order.form.data.delivery_date')}}: *-->
<!--                  </div>-->
<!--                  <div class="col-sm-9">-->
<!--                      <div class="input-group">-->
<!--                          <b-form-select v-model="order.delivery_date" :options="options_delivery_date" tabindex="14" id="deliverydate" ref="deliverydate"></b-form-select>-->
<!--                          <div class="invalid-feedback">-->
<!--                              {{$t('order.form.data.you_missed_this_field')}}-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </div>-->
<!--            </div>-->
<!--            </div>-->
            <div v-if="show_delivery_date">
              <br/>
              <div class="row">
                <div class="col-sm-3">
                  {{$t('order.form.data.delivery_date')}}:
                </div>
                <div class="col-sm-9">
                  <b-datepicker v-model="order.delivery_date" :min="min_date_delivery" :max="max_date_delivery" :disabled="order.region === ''" ref="deliverydate"></b-datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/><br/>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div class="card-header">
            {{$t('order.form.title.payment')}}
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-3">
                  {{$t('order.form.data.payment_type')}}: *
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <select class="form-control" v-model="order.typePayment" id="typePayment" ref="typePayment"
                          tabindex="15"
                          @change="resetError($event)">
                      <option v-for="(item, index) in reference.payment_type" :key="item.value" :value="item.value">
                          {{item.text}}
                      </option>
                  </select>
                  <div class="invalid-feedback">
                      {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>

            </div>
            <br/>
            <div class="row">
              <div class="col-sm-3">
                  {{$t('order.form.data.prepayment')}}: *
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <select class="form-control" v-model="order.prepayment" id="prepayment" ref="prepayment" tabindex="16"
                          @change="resetError($event)">
                      <option v-for="(item, index) in reference.prepayment" :key="item.value" :value="item.value">
                          {{item.text}}
                      </option>
                  </select>
                  <div class="invalid-feedback">
                      {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>

            </div>
            <br/>
              <template v-if="order.prepayment=='1'">
              <div class="row">
                  <div class="col-sm-3">
                      {{$t('order.form.data.prepayment_value')}}: *
                  </div>
                  <div class="col-sm-9">
                      <div class="input-group">
                          <input type="number" class="form-control" v-model="order.prepayment_value" id="prepayment_value" ref="prepayment_value"
                                 min="0"
                                 autocomplete="off" required tabindex="17"
                                 @change="resetError($event)">
                          <div class="invalid-feedback">
                              {{$t('order.form.data.you_missed_this_field')}}
                          </div>
                      </div>
                  </div>
              </div>
              <br/>
                  <div class="row">
                      <div class="col-sm-3">
                          {{$t('order.form.data.prepayment_service')}}: *
                      </div>
                      <div class="col-sm-9">
                          <div class="input-group">
                              <select class="form-control" v-model="order.prepayment_service" id="prepayment_service" ref="prepayment_service" tabindex="18"
                                      @change="resetError($event)">
                                  <option v-for="(item, index) in reference.prepayment_service" :key="item.value" :value="item.value">
                                      {{item.text}}
                                  </option>
                              </select>
                              <div class="invalid-feedback">
                                  {{$t('order.form.data.you_missed_this_field')}}
                              </div>
                          </div>
                      </div>
                  </div>
                  <br/>
                  <div class="row">
                      <div class="col-sm-3">
                          {{$t('order.form.data.prepayment_phone')}}: *
                      </div>
                      <div class="col-sm-9">
                          <div class="input-group">
                              <input type="number" class="form-control" v-model="order.prepayment_phone" id="prepayment_phone" ref="prepayment_phone"
                                     min="0"
                                     autocomplete="off" required tabindex="17"
                                     @change="resetError($event)">
                              <div class="invalid-feedback">
                                  {{$t('order.form.data.you_missed_this_field')}}
                              </div>
                          </div>
                      </div>
                  </div>
                  <br/>
              </template>
            <div class="row">
              <div class="col-sm-3">
                  {{$t('order.form.data.discount_promotional_code')}}: *
              </div>
              <div class="col-sm-9">
                <div class="input-group">
                  <select class="form-control" v-model="order.discount" id="discount" ref="discount" tabindex="18"
                          @change="resetError($event)" :disabled="disabled_discount">
                      <option v-for="(item, index) in reference.discount.free" :key="item.value" :value="item.value" :data-discount="item.data">
                          {{item.text}}
                      </option>
                  </select>
                  <div class="invalid-feedback">
                      {{$t('order.form.data.you_missed_this_field')}}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div class="card-header">
            {{$t('order.form.title.operator_comments')}}
          </div>
          <div class="card-body">
            <textarea class="form-control" rows="3" v-model="order.commentOperator" tabindex="19"></textarea>
          </div>
        </div>
        <br/><br/><br/>
      </div>
    </div>
    <addProduct 
	    :details="details"
	    :site="order.site"
	    @close="hideModal"
	    @addProduct="addProduct"
	    @delProduct="delProduct"
	>
    </addProduct>
    <b-navbar  type="light" variant="light" style="border-top: 1px solid rgba(0, 0, 0, 0.1);">
        <b-button variant="danger" @click="cancelForm">
            {{$t('main.cancel')}}
        </b-button>&nbsp;
      <b-button variant="success" @click="submitForm" :disabled="disabled_send_form">
        <b-icon-calendar-check></b-icon-calendar-check>
          {{$t('main.save')}}
      </b-button>&nbsp;&nbsp;
        <b-form-checkbox switch style="margin-left: 20px" v-model="order.revision">
            Требуется доработка
        </b-form-checkbox>&nbsp;
        <b-form-checkbox switch style="margin-left: 20px" v-model="order.express_delivery" :disabled="!(order.city_obj !== null && typeof order.city_obj.city_id !== 'undefined' && order.city_obj.city_id == 507730)" @change="changeExpress($event)">
          Express Delivery
        </b-form-checkbox>&nbsp;

      <p v-show="alerts.length > 0" style="padding-left: 20px;padding-top: 15px;" class="text-danger">
        {{ alerts[0] }}
      </p>

    </b-navbar>
        <br/>
    </b-overlay>
    <br/><br/>
  </div>
</template>

<style>
table#cart th {
  text-align: center;
}
.btn-square {
    background-position: 50%;
    background-repeat: no-repeat;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    vertical-align: middle;
    width: 30px;
}
.btn-square_cancel {
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20fill='none'%20viewBox='0%200%2024%2024'%3E%3Cpath%20fill='%23AFB9C3'%20d='M7.113%206.176a.6.6%200%200%201%20.848%200L12%2010.214l4.038-4.038a.6.6%200%200%201%20.849%200l.937.937a.6.6%200%200%201%200%20.848L13.785%2012l4.04%204.04a.6.6%200%200%201%200%20.848l-.937.937a.6.6%200%200%201-.849%200L12%2013.784l-4.038%204.04a.6.6%200%200%201-.849%200l-.937-.937a.6.6%200%200%201%200-.848l4.04-4.04-4.039-4.038a.6.6%200%200%201%200-.849l.937-.936Z'/%3E%3C/svg%3E);
    background-size: 16px;
}
</style>

<script>
import Vue from 'vue';
import Axios from 'axios';
import addProduct from "@/components/addProduct";
import cityForm from "@/components/cityForm.vue";
import phoneForm from "@/components/PhoneForm.vue";
import ExpandImage from "@/components/ExpandImage.vue";
import bus from "@/bus";


Vue.filter("formatNumber", function (value) {
  return new Intl.NumberFormat().format(value);
});

export default {
  name: 'Order',

  components: {
    addProduct,
    cityForm,
    phoneForm,
    ExpandImage
  },
    props: [ 'call_id' ],
  data: function () {
    return {
      alerts:             [],                 // Массив сообщений
      loading:            false,
      rules: {
        discount:         false               // права на скидки
      },
//      products:           [],                 // массив товаров
      order: {
        method:           'save',
        ether_id:         -1,
        order_id:         0,                  // если заказ новый то равен 0, если не ноль то заказ редактируется
        manager:          '',                 // Имя менеджера
        typeOrder:        'hsm-sale',         // тип заказа
        site:             'hopshop-uz',       // название магазина
        customer:         {
            customer_id:    undefined,
            name:           '',
            club_card:      '',
            coupons:        []
        },
        lastName:         '',                 // фамилия клиента
        firstName:        '',                 // имя клиента
        sex:              '',                 // Пол клиента
        birthdate:        '',                 // Дата рождения
        phone:            '',                 // телефон клиента
        additionalPhone:  '',                 // дополнительный телефон клиента
        source:           '',                 // источник
        warehouse:        'fulfilment-fargo', // склад отгрузки
        postcode:         '',                 // индекс
        region:           '',                 // регион доставки
        city:             '',                 // город доставки
        city_obj:         null,               // объект города city_id, city_name, area_name, region_name
        address:          '',                 // адрес доставки
        typePayment:      'cash',             // тип оплаты
        prepayment:       '0',                // наличие предоплаты
        prepayment_value: '',                 // сумма предоплаты
        prepayment_service:'',                // идентификатор платежной системы
        prepayment_phone: '',                 // номер телефона для счета
        discount:         'no_discount',      // вариант скидки
        commentOperator:  '',                 // комментарий оператора
        discount_cash:    0,                  // Поле скидки по сумме
        discount_percent: 0,                  // Поле скидки по проценту
        total_product:    0,                  // сумма по товарам
        delivery_cost:    0,                  // стоимость доставки
        coupone_cost:     0,                  // скидка по купонам
        cart:             [],                 // корзина
        coupones:         [],
        delivery_date:    '',                 // дата доставки
        status:           '',                 // статус
        revision:         false,               // требуется доработка
        express_delivery: false,              // Экспресс доставка
        call_id:          0                   // идентификатор звонка
      },
      city_error: false,                      // флаг ошибки в городе, прокидывается в компонент
      disabled_send_form: false,
      disabled_discount:      false,
      mainProps: { width: 80, height: 80, class: 'm1' },
      details: true,
      mode: '',
      options_delivery_date: [],
      delivery_cost: 0,
      so: {
            options_status: []
      },
      localbus_p: new Vue(),
      min_date_delivery: '',
      max_date_delivery: ''
    }
  },

  metaInfo() {
    return {
      title: this.$t('order.title')
    }
  },

  mounted() {
    this.delivery_cost =     this.getDeliveryCost();
    this.order.delivery_cost = this.delivery_cost;

    if (typeof this.$route.params.id === 'undefined') {
        bus.$emit('set-header', this.$t('order.title'));
        this.mode = 'add';
    }else{
        bus.$emit('set-header', this.$t('order.title2'));
        this.mode = 'edit';
        this.order.order_id = this.$route.params.id;
        this.loadOrder(this.order.order_id);
    }
    if (typeof this.call_id !== 'undefined') {
        this.order.call_id = this.call_id;
    }
    console.log('call_id', this.call_id);
  },

  methods: {
    changeQuantity(item, e) {
      let value = e.target.value;
      item.quantity = value;
    },

    setPhoneObj(result){
          console.log(result);
          this.order.customer   = result;
          this.order.firstName  = result.firstName;
          this.order.lastName   = result.lastName;
          this.order.phone      = result.phone;
          this.order.sex        = result.sex;
          this.order.birthdate  = result.birthdate;
          this.order.postcode   = result.postcode;
          this.order.address    = result.address;
          if (this.order.prepayment_phone == '') this.order.prepayment_phone = result.phone;
          this.setCity({ city_id: result.city_id, city_name: result.city_name, area_name: result.area_name, region_name: result.region_name });
    },

    setPhone(val){
          this.order.phone = val;
          this.order.prepayment_phone = val;
    },

    resetCustomer(){
        this.order.customer     = {
                customer_id: undefined,
                name: '',
                club_card: '',
                coupons: []
        };
        this.order.firstName    = '';
        this.order.lastName     = '';
        this.order.sex          = '';
        this.order.birthdate    = '';
        this.order.postcode     = '';
        this.order.city         = '';
        this.order.region       = '';
        this.order.city_obj     = {};
        this.order.address      = '';
    },

    showCustomer(customer_id){
          bus.$emit('portal:customer:open', customer_id);
    },

    loadOrder(order_id){
       this.loading = true;
       Axios
          .post(this.prefix_url + '/api/order?ts=' + Date.now(), {
                   method: 'load',
                   order_id: order_id
               })
          .then(response => {
              console.log(response);
            this.order = response.data.order;
            this.so.options_status = response.data.statuses;
            this.loading = false;
      });
    },

    addProduct(product) {
      let index = this.order.cart.map(function (item) {
        return item.id;
      }).indexOf(product.id);
      if (index == -1) {
        this.order.cart.push({
          id: product.id,
          image: product.image,
          name: product.name,
          amount: parseInt(product.amount),
          quantity: parseInt(product.quantity),
          not_use_discount: product.not_use_discount,
          total: (parseInt(product.amount) * parseInt(product.quantity))
        });
      } else {
        this.order.cart[index].quantity = product.quantity;
      }
    },

    deleteProduct(product) {
      if (confirm(this.$t('order.form.data.are_you_sure_you_want_to_delete'))) {
        this.delProduct(product);
      }
    },

    delProduct(product) {
        let index = this.order.cart.map(function (item) {
          return item.id;
        }).indexOf(product.id);
        this.order.cart.splice(index, 1);
    },

    submitForm() {
      let el = '';

      if (this.order.firstName === '') {
        if (el === '') el = 'firstName';
        this.$refs.firstName.classList.value = this.$refs.firstName.classList.value + " is-invalid";
      }
      if (this.order.sex === '') {
        if (el === '') el = 'sex';
        this.$refs.sex.classList.value = this.$refs.sex.classList.value + " is-invalid";
      }
      if (this.order.warehouse === '') {
        if (el === '') el = 'warehouse';
        this.$refs.warehouse.classList.value = this.$refs.warehouse.classList.value + " is-invalid";
      }
      if (this.order.region === '') {
        if (el === '') el = 'region';
        this.$refs.region.classList.value = this.$refs.region.classList.value + " is-invalid";
      }
      if (this.order.city === '') {
        if (el === '') el = 'city';
        this.city_error = true;
      }
      if (this.order.address === '') {
        if (el === '') el = 'address';
        this.$refs.address.classList.value = this.$refs.address.classList.value + " is-invalid";
      }
      if (this.country == 'pl' && this.order.postcode === '') {
        if (el === '') el = 'postcode';
        this.$refs.postcode.classList.value = this.$refs.postcode.classList.value + " is-invalid";
      }
      if (this.order.typePayment === '') {
        if (el === '') el = 'typePayment';
        this.$refs.typePayment.classList.value = this.$refs.typePayment.classList.value + " is-invalid";
      }
      if (this.order.prepayment === '') {
        if (el === '') el = 'prepayment';
        this.$refs.prepayment.classList.value = this.$refs.prepayment.classList.value + " is-invalid";
      }

      if (this.order.prepayment === '1' && this.order.prepayment_value === '') {
            if (el === '') el = 'prepayment_value';
            this.$refs.prepayment_value.classList.value = this.$refs.prepayment_value.classList.value + " is-invalid";
      }

      if (this.order.prepayment === '1' && this.order.prepayment_service === '') {
            if (el === '') el = 'prepayment_service';
            this.$refs.prepayment_service.classList.value = this.$refs.prepayment_service.classList.value + " is-invalid";
      }

      if (this.order.prepayment === '1' && this.order.prepayment_phone === '') {
            if (el === '') el = 'prepayment_phone';
            this.$refs.prepayment_phone.classList.value = this.$refs.prepayment_phone.classList.value + " is-invalid";
      }

      if (this.order.discount === '') {
        if (el === '') el = 'discount';
        this.$refs.discount.classList.value = this.$refs.discount.classList.value + " is-invalid";
      }

      if (el != '') {
        this.alerts.push(this.$t('order.form.data.errors_were_found_correct_them_and_save_the_order_again'));
        bus.$emit('view-message', { message: this.$t('order.form.data.errors_were_found_correct_them_and_save_the_order_again'), variant: 'danger' });
        this.focusRef(this.$refs[el]);
        return;
      } else {
        if (this.order.phone === '') {
              this.alerts.push(this.$t('order.form.data.errors_were_found_correct_them_and_save_the_order_again'));
              bus.$emit('view-message', { message: this.$t('order.form.data.errors_were_found_correct_them_and_save_the_order_again'), variant: 'danger' });
              return;
        }
        if (this.order.city_obj !== null && this.order.delivery_date === '' && this.show_delivery_date) {
              if (el === '') el = 'deliverydate';
              this.alerts.push(this.$t('order.form.data.select_delivery_date_option'));
              bus.$emit('view-message', { message: this.$t('order.form.data.select_delivery_date_option'), variant: 'danger' });
              this.focusRef(this.$refs[el]);
              return;
        }

        if (this.order.cart.length === 0) {
              this.alerts.push(this.$t('order.form.data.empty_cart_add_items_to_order'));
              bus.$emit('view-message', { message: this.$t('order.form.data.empty_cart_add_items_to_order'), variant: 'danger' });
              return;
        }

        if (!this.ValidPhone(this.order.phone)) {
          if (el === '') el = 'phone';
          this.$refs.phone.classList.value = this.$refs.phone.classList.value + " is-invalid";
          this.alerts.push(this.$t('order.form.data.invalid_phone_number_format'));
          bus.$emit('view-message', { message: this.$t('order.form.data.invalid_phone_number_format'), variant: 'danger' });
          this.focusRef(this.$refs[el]);
          return;
        }

        if (this.order.birthdate!='' && !this.ValidDate(this.order.birthdate)) {
              if (el === '') el = 'birthdate';
              this.$refs.birthdate.classList.value = this.$refs.birthdate.classList.value + " is-invalid";
              this.alerts.push(this.$t('order.form.data.invalid_date_of_birth_format'));
              bus.$emit('view-message', { message: this.$t('order.form.data.invalid_date_of_birth_format'), variant: 'danger' });
              this.focusRef(this.$refs[el]);
              return;
        }

        if (this.order.additionalPhone != '' && !this.ValidPhone(this.order.additionalPhone)) {
          if (el === '') el = 'additionalPhone';
          this.$refs.additionalPhone.classList.value = this.$refs.additionalPhone.classList.value + " is-invalid";
          this.alerts.push(this.$t('order.form.data.invalid_phone_extension_format'));
            bus.$emit('view-message', { message: this.$t('order.form.data.invalid_phone_extension_format'), variant: 'danger' });
          this.focusRef(this.$refs[el]);
          return;
        }
        if (this.order.additionalPhone != '' && this.order.additionalPhone == this.order.phone) {
              if (el === '') el = 'additionalPhone';
              this.$refs.additionalPhone.classList.value = this.$refs.additionalPhone.classList.value + " is-invalid";
              this.alerts.push(this.$t('order.form.data.the_extension_number_must_not_be_equal_to_the_main_phone_number'));
              bus.$emit('view-message', { message: this.$t('order.form.data.the_extension_number_must_not_be_equal_to_the_main_phone_number'), variant: 'danger' });
              this.focusRef(this.$refs[el]);
              return;
        }
      }
      this.order.manager = this.userName;

      let data = JSON.stringify(this.order);

      this.disabled_send_form = true;
      Axios
          .post(this.prefix_url + '/api/order/?ts=' + Date.now(), this.order)
          .then(res => {
            this.disabled_send_form = false;
            if (res.data.success) {
                bus.$emit('view-message', { message: this.$t('order.form.data.order_created_successfully'), variant: 'success' });
                bus.$emit('end-call', parseInt(this.order.call_id));
                this.order.call_id = 0;

                this.close();
                this.$router.push('/orders');
            } else {
              this.alerts.push(res.data.message);
                bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
            }
          })
          .catch(error => console.log(error));
    },

    cancelForm() {
      this.close();
      this.$router.back();
    },

    showModal() {
      this.$root.$emit('bv::show::modal', 'modaladdProduct');
    },

    hideModal() {
      console.log('hideModal');
      this.$root.$emit('bv::hide::modal', 'modaladdProduct');
    },

    ValidPhone(phone) {
        let str = phone.replace(/\D/g, '');
        var re = /^\d{9}$/;
        if (typeof process.env.VUE_APP_COUNTRY === 'undefined' || process.env.VUE_APP_COUNTRY == 'uz' || process.env.VUE_APP_COUNTRY == 'pl') re = /^\d{9}$/;
        else if (process.env.VUE_APP_COUNTRY == 'kz') re = /^\+?7?\d{10}$/;
        else re = /^\+?7?\d{10}$/;

        return re.test(phone);
    },

    ValidDate(date){
        var re = /^\d{4}-\d{2}-\d{2}$/;
        return re.test(date);
    },

    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          ;(ref.$el || ref).focus()
        })
      })
    },

    resetError(e) {
      let el = e.target.id;
      this.$refs[el].classList.value = "form-control";
      if (this.alerts.length > 0) this.alerts = [];

      if (el == 'region') {
        this.order.city             = '';
        this.order.city_obj         = null;
        this.order.delivery_date    = '';

        this.calc_delivery_period();
      }

      if (el == 'discount') {
        if(e.target.options.selectedIndex > -1)
          this.order.discount_percent = parseInt(e.target.options[e.target.options.selectedIndex].dataset.discount);
      }

      if (el == 'prepayment_value'){
        if (this.order.prepayment_value > this.totalOrder){
            this.order.prepayment_value = this.totalOrder;
        }
      }
    },

    changeExpress(e) {
      console.log('changeExpress');
        this.delivery_cost =   this.getDeliveryCost();
        this.order.delivery_cost = this.delivery_cost;
    },

    calc_delivery_period(){
        let period = (this.getDeliveryPeriod(this.order.region));
        let date = new Date();
        let expr = date.setDate(date.getDate() + period );
        console.log('min', new Date(expr), period);
        this.min_date_delivery =  new Date(expr);

        let date2 = new Date();
        expr = date2.setDate(date2.getDate() + period + 2);
        console.log('max', new Date(expr));
        this.max_date_delivery = new Date(expr);

      if ((this.order.city_obj !== null && typeof this.order.city_obj.city_id !== 'undefined' && this.order.city_obj.city_id != 507730 && this.order.express_delivery) || (this.order.city_obj == null || typeof this.order.city_obj.city_id == 'undefined') ) this.order.express_delivery = false;
      this.delivery_cost =   this.getDeliveryCost();
      this.order.delivery_cost = this.delivery_cost;
    },

    setValue(value){
        this.order.city     = value;
        this.order.city_obj = null;
    },

    setCity(city) {
      this.order.city_obj   = city;
      this.order.city       = city.city_name;
      this.order.region     = city.region_name;
      this.city_error       = false;

      this.calc_delivery_period();

    /*
      if (city.city_id == "507730"){

          let diffToday = this.getSecondsToday();
          let today = this.getDateToday();
          let tomorrow = this.getDateTomorrow();
          if (diffToday <= 46800) { //today
              this.options_delivery_date = [
                  { value: today, text: this.$t('order.form.data.delivery_today') },
                  { value: tomorrow, text: this.$t('order.form.data.delivery_tomorrow') },
              ];
          }else{ // tomorrow
              this.order.delivery_date = tomorrow;
              this.options_delivery_date = [
                  { value: tomorrow, text: this.$t('order.form.data.delivery_tomorrow') }
              ];
          }
//          this.order.delivery_cost = 29000;
      }else{
          let d = this.getDatePeriod(this.getDeliveryPeriod(city.region_name));
          this.order.delivery_date = d;
          this.options_delivery_date = [
              { value: d, text: this.$t('order.form.data.delivery_to')+ ' ' + d }
          ];
//          this.order.delivery_cost = this.delivery_cost;
      }
*/
      this.delivery_cost =   this.getDeliveryCost();
      this.order.delivery_cost = this.delivery_cost;
    },

    getDateToday(){
        return this.getDatePeriod(0);
    },

    getDateTomorrow(){
        return this.getDatePeriod(1);
    },

    getDatePeriod(day){
        let today = new Date(Date.now() + 86400000 * day);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    },

    getSecondsToday() {
        let now = new Date();

        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

        let diff = now - today; // разница в миллисекундах
        return Math.round(diff / 1000); // получаем секунды
    },

    getDeliveryPeriod(region) {
        let loc = this.$store.getters.getLocal;
        let regions = loc.regions;
        let obj = regions.find(o => o.text === region);
        if (typeof obj !== 'undefined') return obj.delivery_period;

        return 1;
    },

    getDeliveryCost() {
        let loc = this.$store.getters.getLocal;
        let dc  = loc.cost_by_city;

        if (this.order.express_delivery) return 49000;

        if (this.order.city_obj !== null && typeof this.order.city_obj.city_id !== 'undefined') {
            let obj = dc.find(o => o.city_id == this.order.city_obj.city_id);
            if (typeof obj !== 'undefined') return obj.cost;
        }

        return loc.delivery_cost;
    },

    close(){
        this.order.manager          = '';
        this.order.typeOrder        = 'hsm-sale';
        this.order.site             = 'hopshop-uz';
        this.order.lastName         = '';
        this.order.firstName        = '';
        this.order.sex              = '';
        this.order.birthdate        = '';
        this.order.phone            = '';
        this.order.additionalPhone  = '';
        this.order.source           = '';
        this.order.warehouse        = 'fulfilment-fargo';
        this.order.postcode         = '';
        this.order.region           = '';
        this.order.city             = '';
        this.order.city_obj         = null;
        this.order.address          = '';
        this.order.typePayment      = 'cash';
        this.order.prepayment       = '0';
        this.order.discount         = 'no_discount';
        this.order.commentOperator  = '';
        this.order.discount_cash    = 0;
        this.order.discount_percent = 0;
        this.order.total_product    = 0;
        this.order.delivery_cost    = this.delivery_cost;
        this.order.coupone_cost     = 0;
        this.order.cart             = [];
        this.order.coupones         = [];
        this.order.delivery_date    = '';
        this.order.status           = '';
        this.order.revision         = false;
        this.order.express_delivery = false;
        this.order.customer         = {
                customer_id: undefined,
                name: '',
                club_card: '',
                coupons: []
        };
    }
  },

  computed: {
    userName: function () {
      return this.$store.getters.getUserName
    },

    regions: function () {
        let loc = this.$store.getters.getLocal;
        return loc.regions;
    },

    reference: function () {
          let loc = this.$store.getters.getLocal;
          return loc.reference;
    },

    currency: function () {
        let loc = this.$store.getters.getLocal;
        return loc.currency;
    },

    show_delivery_date: function () {
        let loc = this.$store.getters.getLocal;
        if (typeof loc.show_delivery_date === 'undefined') return false;
        return loc.show_delivery_date;
    },

    totalProducts() {
      let total_product = 0;
      this.order.cart.forEach(function (item) {
        item.total = parseFloat(item.amount) * parseFloat(item.quantity);
        total_product += item.total;
      });
      return total_product;
    },

    totalDiscount() {
      let total_discount = 0, ret = 0;

      if (typeof this.order.customer.club_card !== 'undefined' && this.order.customer.club_card!='') total_discount = 5;
      total_discount += parseFloat(this.order.discount_percent);

      this.order.cart.forEach(function (item) {
        if (item.not_use_discount!='true') ret += parseFloat(item.amount) * parseFloat(item.quantity) * (total_discount / 100);
      });
      return ret;
    },

    totalCoupone() {
      let
              _this = this,
              t1 = 0,
              t2 = 0,
              t3 = 0,
              ret = 0;

      this.order.cart.forEach(function (item) {
              if (item.not_use_discount!='true') t1 += parseFloat(item.amount) * parseFloat(item.quantity);
      });
      t2 = t1 * 0.125;
      _this.order.coupones = [];
      _this.order.customer.coupons.forEach(function (item){
          t3 += parseInt(item.amount);
          console.log('t3', t3);
          if (t3 <= t2) item.disabled = false;
          else {
              item.disabled = true;
              item.select = '';
          }
          if (item.select == item.value) {
              ret += parseInt(item.amount);
              _this.order.coupones.push(item.value);
          }
      });

      if (_this.order.coupones.length > 0){
              _this.disabled_discount = true;
              _this.order.discount = 'no_discount';
              _this.order.discount_percent = 0;
      }else _this.disabled_discount = false;

      _this.order.coupone_cost = ret;
      return ret;
    },

    totalOrder() {
        return this.totalProducts - this.totalDiscount + parseFloat(this.order.delivery_cost) - this.totalCoupone;
    },

    country: function () {
          if (typeof process.env.VUE_APP_COUNTRY === 'undefined') return '';
          return process.env.VUE_APP_COUNTRY;
    },

    rule_order_source: function (){return this.$store.getters.getRule('order_source') },
    rule_tv_admin: function (){return this.$store.getters.getRule('tv_admin') },
    prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }

  },

  watch: {
    order: {
        handler(val) {
            this.delivery_cost = this.getDeliveryCost();
        }
    }
  }
}
</script>